import React, { useEffect } from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Container from '../../components/global/Container';
import Breadcrumbs from '../../components/Breadcrumbs';
import { css } from '@emotion/react';
import Heading from '../../components/global/Heading';
import RichText from '../../components/global/RichText';
// import { getHubDbModel } from './model';
import { useAlgoliaFilter } from '../../app/providers/algoliaFilterProvider';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const BreadcrumbsModule = ({
  breadcrumbs,
  variant,
  title,
  description,
  ...p
}) => {
  const { filters } = useAlgoliaFilter();
  // const currentMake = filters.makeMenu.state.current;
  // const currentModel = filters.modelMenu.state.current;
  // const currentRefinement = currentModel || currentMake || null;
  useEffect(() => {
    //set title
  }, []);

  return (
    <>
      {(!!title || !!breadcrumbs) && (
        <Container
          pt="20px"
          pb="20px"
          yMarginOutside={false}
          {...p}
          color={variant.content == 'blue' ? 'pacificBlue' : 'white'}
          css={css`
            --layout-extra-pl: 0px;
            --layout-extra-pr: 0px;
          `}
          gap="15px"
          flex
          col
        >
          {!!breadcrumbs && (
            <Breadcrumbs
              color={variant.content == 'blue' ? 'white' : 'bahamaBlueLight'}
              breadcrumbs={breadcrumbs}
            />
          )}
          {!!title && title?.content && (
            <Heading
              title={title}
              color={variant.content == 'blue' ? 'white' : 'bahamaBlue'}
            />
          )}

          {!!description?.content && <RichText content={description.content} />}
        </Container>
      )}
    </>
  );
};

export default BreadcrumbsModule;

registerModule({ Breadcrumbs: Self });
